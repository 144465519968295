export default [
  {
    title: "Name",
    key: "NAME",
    minWidth: 80,
  },
  {
    title: "CL OB",
    key: "CL_OB",
    minWidth: 80,
  },
  {
    title: "CL Availed",
    key: "CL_availed",
    minWidth: 80,
  },
  {
    title: "CL CB",
    key: "CL_CB",
    minWidth: 80,
  },
  {
    title: "EL OB",
    key: "EL_OB",
    minWidth: 80,
  },
  {
    title: "EL Availed",
    key: "EL_Availed",
    minWidth: 80,
  },
  {
    title: "EL CB",
    key: "EL_CB",
    minWidth: 80,
  },
  {
    title: "CL Dates",
    key: "CL_Dates",
    minWidth: 80,
  },
  {
    title: "EL Dates",
    key: "EL_Dates",
    minWidth: 80,
  },
];
