<template>
  <FormWrapper :is-loading="isLoading">
    <PageTitle title="Leave Register" slot="title" link="/helpContent/Leave Register" />
    <ValidationObserver ref="validator">
      <FormRow>
          <div class="col-sm-2">
            <Year label="Year" v-model="filtering.year" rules="required" />
          </div>
          <div class="col-sm-3">
            <FormSelect
              label="Select Staff"
              :items="staffList"
              item-name="Name"
              item-value="id"
              v-model="filtering.selectedStaff"
            />
          </div>
          <div class="col-sm-2">
            <InputMonth
              label="Month"
              :items="monthsList"
              item-name="NAME"
              item-value="id"
              v-model="filtering.month"
            />
          </div>
          <div class="col-sm-3">
              <FormButton style="margin-top: 5px" type="primary" @click="search" :rounded="true">
                  <Icon type="ios-search" />
              </FormButton>
          </div>
      </FormRow>
    </ValidationObserver>
    <DataTable
      :actions="true"
      :data="listData"
      :columns="tableConfig"
      :is-loading="isLoading"
      @on-selection-change="selectChange"
      :pagination="pagination"
      @on-page-change="pageChange"
      @on-sort-change="sortChange">
      <template slot="actionsLeft">
      </template>
    </DataTable>
  </FormWrapper>
</template>

<script>
import FormWrapper from "Components/form/FormWrapper";
import PageTitle from "Components/layout/PageTitle";
import DataTable from "Components/DataTable";
import loadingMixin from "Mixins/loadingMixin";
import { leaveRegister, getAllStaffByHRorManager } from "../api";
import tableConfig from "./table";
import Year from "../../../components/Year";
import { ValidationObserver } from "vee-validate";
export default {
  name: "List",
  mixins: [loadingMixin],
  components: {
    FormWrapper,
    DataTable,
    PageTitle,
    Year,
    ValidationObserver
  },
  computed: {
    userData() {
      return this.$store.state.user.user;
    },
  },
  data() {
    return {
      filtering: {
        year: new Date().getFullYear(),
        month: new Date().getMonth(),
        selectedStaff: null,
      },
      staffList: [],
      monthsList: [],
      sorting: {
        key: "id",
        order: "asc",
      },
      pagination: {
        perPage: 10,
        page: 1,
        total: 0,
      },
      tableConfig,
      listData: [],
      selectedRows: [],
      showMessage: {
        isVisible: false,
        message: "",
      },
    };
  },
  created() {
    this.staffListData();
  },
  methods: {
    async staffListData() {
      this.showLoader();
      getAllStaffByHRorManager().then((res) => {
        this.staffList = res.data;
        this.filtering.selectedStaff = this.userData.user_id;
      });
      this.hideLoader();
      setTimeout(() => {
        this.getDataByFilter();
      }, 2000);
    },
    search() {
      this.pagination.page = 1;
      this.getDataByFilter();
    },
    selectChange(data) {
      this.selectedRows = data;
    },
    sortChange(data) {
      this.sorting.key = data.key;
      this.sorting.order = data.order;
      this.getDataByFilter();
    },
    getDataByFilter() {
      this.$refs.validator.validate().then((result) => {
          if (result) {
          setTimeout(() => {
            this.showLoader();
            const { key, order } = this.sorting;
            const { perPage, page } = this.pagination;

            const filters = {};
            filters.year = this.filtering.year;
            filters.month = this.filtering.month;
            filters.selectedStaff = this.filtering.selectedStaff;
            const data = {
              filterjson: {
                filter: [filters],
                sort: [{ key, order }],
                paging: [
                  {
                    startIndex: page,
                    pagesize: perPage,
                  },
                ],
              },
            };
            leaveRegister(data)
              .then(this.handleResponse)
              .catch(this.handleError);
          }, 50);
        }
      });
    },
    pageChange(page) {
      this.pagination.page = page;
      this.getDataByFilter();
    },
    handleResponse(response) {
      this.hideLoader();
      this.listData = response.data;
      this.pagination.total = response.NumberOfRecords;
    },
  },
};
</script>

<style lang="scss" module>
.popupRow {
  padding: 5px 0 15px;
}
.pagination {
  margin: 10px 0 0;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  line-height: 26px;
}

.paginationText {
  margin: 0 20px;
}

.paginationButton {
  cursor: pointer;
  width: 26px;
  text-align: center;
  transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out,
    box-shadow 200ms ease;
}
.paginationButton:hover {
  background: #57a3f3;
  color: #fff;
  &:hover {
    box-shadow: 0 5px 11px 0 rgba(0, 0, 0, 0.18),
      0 4px 15px 0 rgba(0, 0, 0, 0.15);
  }
}

.filter {
  margin: -10px 0 10px;
}

.filterItem {
  display: inline-block;
  margin: 0 15px 15px 0;
}

.filterItemCheckbox {
  flex-basis: auto;
  align-self: flex-start;
  margin-top: 13px;
}
</style>
